import {useEffect, useState} from "react";
import { globalState } from "providers";
import styled from "styled-components";
import { firebase, roomRead } from "../services/firebase";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollection } from 'react-firebase-hooks/firestore';

import { readMessages } from "../services/firebase";

const Container = styled.div`
  overflow: auto;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 4rem;
  bottom: 4rem;
  flex-direction: column;
  justify-content: center;
  * {
    overflow-anchor: none;
  }
`

const Anchor = styled.div`
  overflow-anchor: auto;
  height: 3.5rem;
`

const Envelope = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
    align-self: flex-start;
  &.is_current_user{
    align-self: flex-end;
  }
`
const Body = styled.div`
  padding: 0.5rem 0.5rem 0;
${({theme: {colors}}) =>`
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: auto;
  background: ${colors('foreground')};
  color: ${colors('background')};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  &.is_current_user{
    align-self: flex-end;
    background: ${colors('chrome')};
    color: ${colors('foreground')};
    justify-content: flex-end;
  }
`}
`

const Header = styled.div`
  font-weight: 500;
  padding: 0 0 0.5rem;
`

const Message = ({className, message}) => {
  return <Envelope className={className}>
    <Header>{message.data().user}:</Header>
    <Body className={className} dangerouslySetInnerHTML={{__html: message.data().message}} />
    </Envelope>
}
export const Messages = ({room = 'general'}) => {
  const [{session}, dispatch] = globalState();
  const [snapshot, loading, error] = useCollection(query(collection(firebase, `rooms/${room}/chat`), orderBy("created")));

  useEffect(()=>{
    roomRead(room, session).then()
    return () => roomRead(room, session).then()
  },[])

  return (<Container>
    {snapshot && snapshot.docs.map(message => <Message className={session.displayName ===  message.data().user ? "is_current_user" : "is_message"} key={message.id} message={message} />)}
      <Anchor />
    </Container>)
}
