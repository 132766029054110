import sessionReducer from "./session";
import roomReducer from "./room";

export const Reducer = (
  {
    room,
    session,
  },
  action
) => {
  return {
    room: roomReducer(room, action),
    session: sessionReducer(session, action),
  };
};

export default Reducer;
