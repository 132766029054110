import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
  *, :after, :before {
    box-sizing: border-box;
  }

  html {
    background-color: ${({ theme }) => theme.colors("background")};
    color: ${({ theme }) => theme.colors("foreground")};
  }

  body {
    background-color: ${({ theme }) => theme.colors("background")};
    color: ${({ theme }) => theme.colors("foreground")};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0;
    min-height: 100vh;
    max-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      /* mobile viewport bug fix */
      min-height: -webkit-fill-available;
    }
  }

  a {
    color: ${({ theme }) => theme.colors("link")};
    text-decoration: none;
    &:visited{
      color: ${({ theme }) => theme.colors("link", 0.5)};
    }
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors("background", 0.15, true)};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  .leaping_logo {
    animation: leap 4s infinite linear both;
    stroke-dasharray: 200;
    stroke-dashoffset: 420;
  }

  .bouncing_logo {
    animation: bounce 3s infinite linear both;
    stroke-dasharray: 200;
    stroke-dashoffset: 420;
  }

  @keyframes leap {
    0% {
      stroke-dashoffset: 420;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes bounce {
    0% {
      stroke-dashoffset: 420;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 420;
    }
  }

  .stop-animation * {
    animation: none !important;
    transition: none !important;
  }

  b {
    font-weight: 400;
  }
  ::-webkit-datetime-edit{
    border-color: #369;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  ::-webkit-datetime-edit-fields-wrapper{
    border-color: #369;
    padding: 0.5rem;
    border-radius: 2px;
    &:focus-within {
      border-color: ${({ theme }) => theme.colors("text")};
    }
  }
  ::-webkit-datetime-edit-text{
  }
  ::-webkit-datetime-edit-month-field{
  }
  ::-webkit-datetime-edit-day-field{
  }
  ::-webkit-datetime-edit-year-field{
  }
  ::-webkit-inner-spin-button{
  }
  ::-webkit-calendar-picker-indicator{
    opacity: 0.25;
    margin-right: 0.25rem;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors("text")};
    transition: background-color 5000s ease-in-out 0s;
  }
`;
