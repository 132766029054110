import styled from "styled-components";
import { Icon } from "./Icon";

const Container = styled.header`
${({theme: {colors}}) =>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  opacity: 0.75;
  background: ${colors('chrome')};
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
`}
`

const Back = styled.div`
  margin: 0 0.5rem;
`

const Section = styled.div`
  margin: 0 1rem; 
`

export const Header = ({goBack, room}) => {

  const back = () => {goBack()}
  return (<Container><Section>{room ? <Back onClick={back}><Icon icon="arrow-left" /></Back>: null}</Section><Section>dannawara.com</Section></Container>)
}
