import { useEffect, useState } from "react";
import { globalState } from "providers";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

import { Header } from "./Header";

import { ChatInput } from "./ChatInput";

import { Rooms } from "./Rooms";

import { Messages } from "./Messages";

export const Terminal = () => {
  const [{room}, dispatch] = globalState();
  const [currentRoom, setRoom] = useState();

  const chooseRoom = (room) => {
    if(!room) return dispatch({type: "CLEAR_ROOM"});
    dispatch({type: "SET_ROOM", data: {room} });
  }


  useEffect(()=>{
    setRoom(room?.room);
  }, [room])

  return (<Container>
    <Header room={currentRoom} goBack={chooseRoom}/>
    { currentRoom ? <Messages room={currentRoom} /> : <Rooms setRoom={chooseRoom}/> }
    { currentRoom ? <ChatInput room={currentRoom} /> : null}
    </Container>);
}
