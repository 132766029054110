import styled from "styled-components";

export const Input = styled.input`
${({theme: {colors}}) =>`
  border-radius: 0.5rem;
  margin: 0.5rem;
  flex: 1;
  height: 3rem;
  max-height: 3rem;
  margin: 0;
  padding: 0 0.5rem;
  display: flex;
  background: ${colors('chrome')};
  color: ${colors('foreground')};
  border: 0 none;
  outline: none;
  font-size: 1.1rem;
  &.flat_left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.flat_right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`}  
`
