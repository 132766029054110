import  { useEffect, useState} from "react";
import { globalState } from "providers";
import { createRoot } from 'react-dom/client';
import { firebase } from "./services/firebase";
import { StateProvider, ThemeProvider } from "providers";
import {GlobalStyle } from "./theme";
import { Primary } from "containers";
import reducers from "reducers";
import { Auth, Terminal } from "components";

// Render your React component instead
const root = createRoot(document.getElementById('app'));

const Providers = ({children}) => {
  return (
    <StateProvider initialState={{}} reducer={reducers}> 
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </StateProvider>
  )
}

const App = () => {
  const [{session}, dispatch] = globalState();
  const [user, setUser] = useState(session);

  useEffect(() => {
    setUser(session);
  },[session])

return (
  <>
        <GlobalStyle />
        { !user && !session ? <Auth setUser={setUser}/> : null }
        <Primary>
          <Terminal user={user}/>
        </Primary>
</>
)
}

root.render(<Providers />);
