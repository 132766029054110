import styled from "styled-components";

const Container = styled.div`
${({theme: {colors}}) =>`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors('background')};
  z-index: 1;
`}
`

export const Primary = ({children}) => {
  return (<Container>{children}</Container>)
}
