import  { useEffect, useState } from "react";
import { globalState } from "providers";
import styled from "styled-components";
import { addRoom, messageCount, firebase } from "../services/firebase";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollection } from 'react-firebase-hooks/firestore';
import {Button} from "./Button";
import { Input } from "./Input";
import { Icon } from "./Icon";
const Container = styled.div`
  margin-top: 4rem;
`

const List = styled.div`

`

const Count = styled.div`
${({theme: {colors}}) =>`
  border-radius: 50%;
  margin: 0.5rem;
  background: ${colors('link')};
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`}
`

const ListItem = styled.div`
  padding: 1rem 0.5rem;
${({theme: {colors}}) =>`
  border-radius: 0.5rem;
  margin: 0.5rem;
  background: ${colors('chrome')};
  display: flex;
  justify-content: space-between;
`}
`
const Wrap = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 4rem;
  max-height: 4rem;
  margin: 0;
  padding: 0.5rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

export const Room = ({room, setRoom}) => {
  const [{session}, dispatch] = globalState();
  const [count, setCount] = useState();

  useEffect(()=>{
    messageCount({room, user: session}).then(setCount)
  },[])

  const chooseRoom = (room) => {
    setRoom(room);
  }

  return (
    <ListItem onClick={() => chooseRoom(room)}><>{room}</>{ count ? <Count>{count}</Count> : <></>}</ListItem>
  )
}

export const Rooms = ({setRoom}) => {
  const [value, setValue] = useState("");

  const [snapshot, loading, error] = useCollection(query(collection(firebase, `rooms/`), orderBy("created")));

  const createRoom = () => {
    addRoom(value);
    setValue("");
  }
  const handler = ({target: {value}}) => {
    setValue(value);
  }

  return (<Container>
      <List>
      {snapshot && snapshot.docs.map(room => <Room key={room.id} room={room.id} setRoom={setRoom}/> )}
        <Wrap><Input className="flat_right" onChange={handler} placeholder="add a chat room"/><Button className="flat_left" onClick={createRoom}><Icon icon="plus" /></Button></Wrap>
      </List>
    </Container>);
}
