import { useEffect, useState } from "react";
import { globalState } from "providers";
import styled from "styled-components";

import { sendMessage } from "../services/firebase";

import { Button } from "./Button";
import { Input } from "./Input";

const commands = {
  login: () => {
    console.log("LOGIN");
  }
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 4rem;
  max-height: 4rem;
  margin: 0;
  padding: 0.5rem;
  display: flex;
  position: fixed;
  bottom: 0;
`

export const ChatInput = ({onSubmit, room = "general", user = "anon"}) => {
  const [{session}, dispatch] = globalState();
  const [value, setValue] = useState("");

  const handleSubmit = (  ) => {
   onSubmit(value); 
  }

  const handleChange = ({target: {value}}) => {
    setValue(value);
  }

  const runCommand = (cmd) => {
    cmd = cmd.slice(1);
    if(commands[cmd]) commands[cmd]();
    setValue("");
  }

  const doSend = () => {
    if(value[0] === "/") return runCommand(value);
    sendMessage({user: session.displayName || user, room: room, message: value})
    setValue("");
  }

  const handleKeyDown = ({ key, shiftKey }) => {
    if (key === "Enter" && !shiftKey ) {
      doSend();
    }else if(key === "Enter" && shiftKey ) {
      setValue(`${value}<br />`)
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, {
      passive: true,
    });
    return () => {
      window.removeEventListener("keydown", handleKeyDown, {
        passive: true,
      });
    };
  }, [value]);

  return (
    <Container>
      <Input className="flat_right" value={value} onChange={handleChange} />
      <Button className="flat_left" onClick={doSend}>Send</Button>
    </Container>
  )
}
