import { initializeApp } from "firebase/app";
import { collection, doc, addDoc,  getCountFromServer, getDocs, getDoc, getFirestore, setDoc, serverTimestamp} from "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT,
  storageBucket: process.env.FIREBASE_BUCKET,
  messagingSenderId: process.env.FIREBASE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const messageCount = async ({room, user}) => {
  const coll = collection(db, `rooms/${room}/chat`);
  const snapshot = await getCountFromServer(coll);
  const userRoom = await getDoc(doc(db, `users/${user.email}`));
  console.log("USERROOMM", userRoom.exists());
  let lastCount = (userRoom.exists()) ? userRoom.data()[room] : 0;
  if(!lastCount) lastCount = 0;
  console.log("count", lastCount);
  return snapshot.data().count - lastCount;
}

export const roomRead = async (room, user) => {
  const coll = collection(db, `rooms/${room}/chat`);
  const snapshot = await getCountFromServer(coll);
  await setDoc(doc(db, `users`, user.email), {
    id: user.email,
    name: user.email,
    [room]: snapshot.data().count,
    created: serverTimestamp()
  }, {merge: true});
}

export const addItem = async (path, doc) => {
  try {
    const docRef = await addDoc(collection(db, path), doc);
  } catch (e) {
    console.error("Error adding document: ", e);
  }

}
export const sendMessage = async ({user, room, message}) => {
  try {
    const docRef = await addDoc(collection(db, `rooms/${room}/chat`), {
      user,
      message,
      created: serverTimestamp()
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }

}

export const addRoom = async (room) => {
  try {
    const docRef = await setDoc(doc(db, `rooms`, room), {
      id: room,
      name: room,
      created: serverTimestamp()
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export const firebase = db;
