import styled from "styled-components";
export const Button = styled.div`
${({theme: {colors}}) =>`
  background-color: ${colors('link')};
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.flat_left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.flat_right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`}
`
